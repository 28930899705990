<template>
  <div class="w-100 zindex-4 py-1" style="top:0;left: 0; background: rgba(242, 242, 242, 1);">
  <div class="d-flex justify-content-around ml-1-mobile registerStepBar" >
    <div v-for="i in step" class="col-xs-mobile registerStep">
      <div class="d-flex lg:justify-content-center position-relative ">
            <span class="d-flex justify-content-center align-items-center text-white registerStepHeader zindex-2" :class="i.value<=currentStep?'currentStep':''">{{
                i.value
              }}</span>
        <div class="register-progress-bar "  :class="i.value<currentStep?'currentStep':''" v-if="i.value<3"></div>
      </div>
      <div class="mt-1 ml-2 registerStep__text text-center" :class="i.value<=currentStep?'currentStepText':''" >{{ i.text }}</div>
    </div>
  </div>
  </div>

</template>

<script>
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, min, email} from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from "v-select2-component";
import OtpInput from "@bachdgvn/vue-otp-input";
import {
  BCardHeader,
  BCard,
  VBTooltip,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BPagination,
  BCardText,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  TabsPlugin,
  BTabs,
  BTab,
} from "bootstrap-vue";

import {WebCam} from "vue-web-cam";
import Vue from "vue";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";
import Fuse from "fuse.js";
import {v4 as uuidv4} from "uuid";
import {$ctx} from "@themeConfig";
import {forEach} from "postcss-rtl/lib/affected-props";
import {formatNumber} from "@core/utils/utils";
import SurveyCES from "@/components/SurveyCES.vue"
import LuckyNumber from "@/components/LuckyNumber.vue"
import LuckyNumberMB from '@/components/LuckyNumberMB.vue'
import ModalDetaiEsim from '@/components/ModalDetaiEsim.vue'
import {mapMutations} from 'vuex';
import {value} from "dom7";

export default {
  name: "RegisterStep",
  components: {
    BCardHeader,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    FeatherIcon,
    BFormCheckbox,
    BCardText,
    BPagination,
    "vue-web-cam": WebCam,
    VBTooltip,
    StarRating,
    Fuse,
    BImg,
    TabsPlugin,
    BTabs,
    BTab,
    "v-otp-input": OtpInput,
  },
  data() {
    return {
      step: [
        {
          value: 1,
          text: "Thông tin"
        },
        {
          value: 2,
          text: "Tính phí"
        }, {
          value: 3,
          text: "Thanh toán"
        }
      ]
    };
  },
  props: {
    currentStep: {
      type: Number,
    }
  },
  mounted() {
  },
}
</script>

<style scoped>
.registerStep {
  max-width: 10rem;
}
.registerStepBar{
  margin-left: auto;
  margin-right: auto;
  max-width: 100rem;
  font-size: medium;
}
.registerStepHeader {
  background-color: #fabfc0;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  margin-left: 2rem;
}

.register-progress-bar {
  height: 10px;
  background-color: white;
  position: absolute;
  width: 30rem;
  left: 6rem;
  top: 22px;
  z-index: 1
}
.currentStep{
  background-color: #ED1F24;

}
.currentStepText{
  font-weight: 600;
}
@media screen and (max-width: 1080px) {
  .registerStep {
    max-width: 10rem;
  }
  .registerStepHeader {
    margin-left: 3rem;
    height: 4rem;
    width: 4rem;
  }
  .register-progress-bar {
    width: 26rem;
    height: 7px;
    top: 1.5rem;
    left: 5rem;
  }
}

@media screen and (max-width: 450px) {
  .registerStep {
    max-width: 10rem;
  }
  .registerStepHeader {
    margin-left: 2.5rem;
    height: 2.5rem;
    width: 2.5rem;
  }
  .currentStepText {
    margin-left: 0.5rem !important;
  }
  .registerStep__text {
    margin-left: 7px !important;
  }
  .register-progress-bar {
    width: 13rem;
    height: 5px;
    top: 1rem;
    left: 3rem;
  }

}
</style>